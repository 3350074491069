<template>
  <client-card :client="client" :dismiss="dismiss" :loading="loading" :on-submit="onSubmit" />
</template>

<script>
import { ClientModalMixin } from '../mixins'
import { ClientCard } from '../components'

export default {
  name: 'ModalClient',
  mixins: [ClientModalMixin],
  components: {
    ClientCard
  }
}
</script>
