import { mapActions } from 'vuex'
import { NAMESPACE } from '../store'
import { Modal } from '@argon/app/mixins'

export default {
  name: 'ClientModalMixin',
  mixins: [Modal],
  props: {
    clientId: { type: [String, Number], required: true }
  },
  data: () => ({
    client: null,
    loading: true
  }),
  methods: {
    ...mapActions(NAMESPACE, ['getClient', 'patchClient', 'createClient']),
    onSubmit(data) {
      let callback = this.clientId === 'new' ? this.createClient : this.patchClient
      return callback(data).then((res) => this.onResult(res))
    },
    onResult(client) {
      return new Promise((resolve, reject) => {
        resolve(true)
      })
    }
  },
  created() {
    if (this.clientId === 'new') {
      return (this.loading = false)
    }
    this.getClient(this.clientId)
      .then((client) => {
        this.client = client
      })
      .finally(() => {
        this.loading = false
      })
  }
}
